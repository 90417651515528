import useTranslation from 'next-translate/useTranslation'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import styles from '../../styles/Masonry.module.css'
import { DB_PHOTOS_SEPERATOR, PRODUCTS_PER_PAGE } from '../../utils/constants'
import { buildProductLink, currencyCodeToSymbol, destroyProductLink, formatMoney, getCurrentPackageDaysLeft, imageUrlToDimension, isClient, userLink } from '../../utils/functions'
import TextView from '../../views/TextView'
import StarRatings from 'react-star-ratings'
import Link from '../../views/Link'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { apiPostJson } from '../../api/client'
import EmptyView from '../EmptyView'
import ImageView from '../../views/ImageView'
import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const PRODUCT_IMAGE_MAX_WIDTH = 600
const PRODUCT_IMAGE_MAX_HEIGHT = 600
const Product = ({ asPlaceHolder, product, viewer, excludeUnSaved }) => {
    const { t, lang } = useTranslation('common')
    if(asPlaceHolder) product = `${product}`
    const photos = asPlaceHolder? [] : product?.photos?.split(DB_PHOTOS_SEPERATOR)
    const photo = asPlaceHolder? "#" : photos[0]
    var productLink = asPlaceHolder? "#" : buildProductLink(product?.id, product?.title, product?.catTextId, product?.subcatTextId)
    //console.log("Product:viewer", viewer, product)
    if(viewer?.id == product.sellerId) {
        productLink += "?preview=1"
    }
    const [liked, setLiked] = useState(product?.liked)
    useEffect(() => {
        setLiked(product.liked)
    }, [product.liked])

    //console.log("PROD:photo", dimensions, photo)
    //console.log("PROD:", product)

    const boosterRem = asPlaceHolder? 0 : getCurrentPackageDaysLeft(new Date(product.boosterExpiry) || new Date())
    //console.log("PROD:r", boosterRem)

    const toggleLike = e => {
        e.preventDefault()
        if(!viewer) {
            Swal.fire({
                text: t('common:require-login-message'),
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: t('common:cancel'),
                confirmButtonText: t('header:sign-in')
            })
            .then(result => {
                if(result.isConfirmed) {
                    signIn()
                }
            })

        } else {
            setLiked(!liked)
        
            //api
            apiPostJson('update-list', {product_id: product.id}, lang)
            .then(r => {}).catch(e => {})
        }
    }

    const truncText = (text, maxLength, moreText) => {
        if(text.length <= maxLength) return text
        return <>{text.substring(0, maxLength)}<span dangerouslySetInnerHTML={{__html: moreText}}></span></>
    }

    const img2 = (
        asPlaceHolder?
        <Skeleton
            isDefaultHost src={photo} 
            width={{base: "112px", md: "210px"}}
            height={{base: "112px", md: "210px"}}
            maxW={{base: "112px", md: "210px"}}
            maxH={{base: "112px", md: "210px"}}
            className={""}
            intrisicHeight="210px"
            marginInlineStart="0px !important" 
        />
        :
        <ImageView
            isDefaultHost src={photo} 
            width={"auto"}
            height={{base: "112px", md: "210px"}}
            maxW={{base: "112px", md: "210px"}}
            maxH={{base: "112px", md: "210px"}}
            className={"w-100 h-100"}
            intrisicHeight="210px"
            marginInlineStart="0px !important" 
        />
    )

    //if(excludeUnSaved && !liked && !asPlaceHolder) return null
    
    return (
        <HStack
        w="100%" 
        mb="15px" 
        color="#4f4f4f" 
        bgColor="#fff" 
        textDecor="none" 
        boxShadow="0 3px 5px 0 rgb(50 50 50 / 8%)" 
        pos="relative"
        h={{base: "112px", md: "210px"}} borderRadius="15px">
            <HStack bg="#E2E8F0" justifyContent="center" alignItems="center"
             as={Link} href={productLink} _hover={{textDecor: "none", color: "#4f4f4f"}}
            w={{base: "112px", md: "210px"}} 
            h={{base: "112px", md: "210px"}} 
            minW={{base: "112px", md: "210px"}}
            pos="relative"
            mr="10px">
                {img2}
                <HStack pos="absolute" left="0" right="0" top="0" bottom="0" mt="5%" justifyContent="flex-end" alignItems="flex-start" width="100%">
                    <Box opacity={product?.is_flash? 1 : 0} borderTopLeftRadius="4px" borderBottomLeftRadius="4px" 
                    p="5px" textTransform="uppercase" fontWeight="bold">
                        <svg style={{ width: "32px", height: "32px", maxWidth: "32px", maxHeight: "32px", fill: "rgb(255, 255, 255)", stroke: "inherit" }}>       
                            <image style={{width: "100%", height: "100%"}} xlinkHref="/res/images/flash.svg" />    
                        </svg>
                    </Box>
                </HStack>
                <VStack w="100%" h="100%" justifyContent="flex-end" alignItems="flex-start" 
                pos="absolute"
                top="0" left="0" right="0" 
                marginInlineStart="0px !important">
                    <Box
                    fontSize="10px" lineHeight="1.2em" color="#fff" fontWeight="400" 
                    bgColor="rgba(0,0,0,.5)" borderRadius="0 8px 0 0" py="4px" px="8px">
                        {photos?.length || <Skeleton />}
                    </Box>
                </VStack>
            </HStack>

            <VStack marginInlineStart="0px !important" 
            overflow="hidden"
            justifyContent="space-between" alignItems="flex-start" h="100%" w="100%">
                <VStack marginInlineStart="0px !important" p="5px" 
                as={Link} href={productLink} _hover={{textDecor: "none", color: "#4f4f4f"}} 
                w="100%" 
                justifyContent="flex-start" alignItems="flex-start">
                    <VStack marginInlineStart="0px !important" w="100%"
                    justifyContent="flex-start" alignItems="flex-start">
                        <Text as="div" w={{base: "100%"}} 
                        lineHeight={{base: "1.1em", md: "1.4em"}} 
                        my="15px" fontSize={{base: "14px !important", md: "1rem !important"}}
                        fontWeight="700" className="more-text">
                            {product?.title || <Skeleton count={2} />}
                        </Text>
                        <HStack marginInlineStart="0px !important" textAlign="right" as="div" 
                        w={{base: "100%"}} justifyContent="flex-end" mt="0px !important">
                            <Text as="div" fontWeight="700" color="primary.900" textAlign="right"
                            fontSize={{base: "15px", md: "17px"}} pr="15px !important"
                            lineHeight="20px" whiteSpace="no-wrap">
                                {currencyCodeToSymbol(product?.currency_code || "")}{asPlaceHolder? "" : formatMoney(product?.price, lang, 2)}
                            </Text>
                        </HStack>
                    </VStack>

                    <Text as="div" w="90%" fontSize="14px" display={{base: "block", md: "none"}} className="more-text">
                        {product?.description}
                    </Text>
                    <Text as="div" fontSize="14px" mt="15px" display={{base: "none", md: "block"}}>
                        { 
                            asPlaceHolder? 
                            null : 
                            truncText(product?.description, 100, "&#8230;")
                        }
                    </Text>
                </VStack>

                <HStack alignSelf="flex-end" mt="-10px !important" justifyContent="space-between" alignItems="center" width="100%">                 
                    <HStack display={{base: "none", md: "flex"}} pos="relative" justifyContent="center" alignItems="center" m="8px">
                        <Button aria-label="Toggle save" onClick={asPlaceHolder? () => {} : toggleLike}
                            w={{base: "48px"}} h={{base: "48px"}} 
                            p="5px"
                            borderRadius="50%"
                            isRound="true"
                            background="white"
                            color="primary.900"
                            variant="solid" 
                            boxShadow="0 1px 2px rgb(96 125 135 / 15%)" pos="relative">
                            {
                                asPlaceHolder?
                                <Skeleton />
                                :
                                <Box className={`fa fa-2x fa-star${liked? "" : "-o"}`}
                                fontSize={{base: "1.1em", md: "2em"}}></Box>
                            }
                        </Button>
                    </HStack>
                    <Box opacity={boosterRem > 0? 1 : 0} bg="primary.900" color="#fff" borderTopLeftRadius="4px" borderBottomLeftRadius="4px" 
                    p="5px" textTransform="uppercase" fontWeight="bold">
                        Top +
                    </Box>
                </HStack>
            </VStack>
        </HStack>
    )
}

export default function Products({loading, products, viewer, excludeUnSaved}) {
    const { t } = useTranslation('common')

    //if(loading) return <LoadingView />

    //if(!products || !Array.isArray(products)) return null

    //if(products.length == 0) return <EmptyView message={t('common:no-result')} />

    const ids = []
    return(
        <div id="products">
            <Flex flexWrap="wrap" ml="-7px" mr={{base: "-7px", md: "15px"}}>
                {
                    (products /*&& products.length > 0*/? products : [...Array(PRODUCTS_PER_PAGE).keys()]).map((product, i) => {
                        if(!ids.includes(product?.id || product)) {
                            ids.push(product?.id || product)

                        } else {
                            return null
                        }
                        return  <Product 
                            asPlaceHolder={!products || products.length == 0} 
                            product={product} 
                            viewer={viewer} key={product?.id || product} excludeUnSaved={excludeUnSaved} />
                    })
                }
            </Flex>
        </div>
    )
}
